@import "~tailwindcss/base";
@import "~tailwindcss/utilities";
@import "~tailwindcss/components";
@import "~@materializecss/materialize/sass/components/color-variables";
@import "~@materializecss/materialize/sass/components/color-classes";
@import "~@materializecss/materialize/sass/components/variables";
@import "~@materializecss/materialize/sass/components/global";
@import '~@materializecss/materialize/sass/components/forms/input-fields';
@import '~@materializecss/materialize/sass/components/forms/select';
@import "~@materializecss/materialize/sass/components/dropdown";
@import "~@materializecss/materialize/sass/components/collapsible";
@import "~@materializecss/materialize/sass/components/tooltip";
@import '../stylesheets/actiontext';
@import "~trix/dist/trix";
@import '../stylesheets/pulse_button';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html{
    font-size: 14px;
  }
}

@layer components {
  .button{
    @apply py-2 px-4 rounded contrast-text dark:text-gray-300 bg-blue-500 hover:bg-blue-700 cursor-pointer shadow hover:shadow-xl dark:bg-blue-700 dark:hover:bg-blue-500;
  }
  .button-green{
    @apply py-2 px-4 rounded contrast-text dark:text-gray-300 bg-green-500 hover:bg-green-700 cursor-pointer shadow hover:shadow-xl dark:bg-green-700 dark:hover:bg-green-500;
  }
  //.active{
  //  @apply bg-blue-700
  //}
  .nav-li{
    @apply w-60 bg-transparent hover:bg-blue-700 pl-4 py-2 my-2 cursor-pointer
  }
  ul.dropdown-content{
    @apply dark:bg-dark-700 dark:contrast-text
  }
  .contrast-text{
    @apply text-white dark:text-gray-300
  }
}

@layer utilities {
  .font-title{
    font-family: 'Racing Sans One', cursive;
  }
  .font-google{
    font-family: 'Roboto', sans-serif;
  }
}